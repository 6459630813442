<template>
  <v-list-item @click="$emit('selectTemplate', template)">
    <v-list-item-avatar v-if="template.namespace ==='giftcard'">
      <v-icon>mdi-wallet-giftcard</v-icon>
    </v-list-item-avatar>
    <v-list-item-avatar v-else-if="template.namespace === 'coupon'">
      <v-icon>mdi-ticket-percent-outline</v-icon>
    </v-list-item-avatar>
    <v-list-item-avatar v-else-if="template.namespace === 'voucher'">
      <v-icon>mdi-ticket-confirmation-outline</v-icon>
    </v-list-item-avatar>
    <v-list-item-avatar v-else>
      <v-icon>mdi-view-quilt-outline</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ template.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ template.type ? template.type : $t('missingType') }}  - {{ template.namespace ? template.namespace : $t('missingNamespace') }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	name: 'TemplateListItem',
	props: {
		template: {
			type: Object,
			required: true
		}
	},
	watch: {
		template: {
			handler(value) {
				//make sure that the data that is updated here is passed to selectedTemplate for profile data to be updated after changes
				this.$emit('updateSelectedTemplate', value)
			},
			deep: true
		}
	}
}
</script>
